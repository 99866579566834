<template>
  <div>
    <LinkAnprCameraItem
      v-for="camera in cameras"
      :key="camera.exid"
      :value="camera"
      @change="updateCameraItem"
    />
  </div>
</template>

<script>
import LinkAnprCameraItem from "@/components/gateReport/LinkAnprCameraItem"
export default {
  name: "LinkAnprCamerasSelector",
  components: {
    LinkAnprCameraItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cameras: [],
    }
  },
  watch: {
    value: {
      handler(cameras) {
        this.cameras = cameras
      },
      immediate: true,
    },
    cameras: {
      handler(cameras) {
        this.$emit("input", cameras)
      },
      deep: true,
    },
  },
  methods: {
    updateCameraItem(updatedCamera) {
      const index = this.cameras.findIndex(
        (camera) => camera.exid === updatedCamera.exid
      )
      if (index > -1) {
        this.cameras[index] = updatedCamera
      }
    },
  },
}
</script>
