<template>
  <div class="d-flex align-center">
    <v-switch
      v-model="camera.isEnabled"
      color="primary"
      class="pt-0 pb-2 caption center"
      dense
      hide-details
      :label="`${camera.name}(${camera.exid})`"
    />
    <v-select
      v-if="camera.isEnabled"
      v-model="camera.type"
      :style="{ 'max-width': '100px' }"
      :items="anprCameraTypes"
      hide-details
      dense
      label="Type"
      class="ml-2 pa-0"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "LinkAnprCameraItem",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      anprCameraTypes: [
        { value: "in", text: "In" },
        { value: "out", text: "Out" },
        { value: "in-out", text: "In / Out" },
      ],
      camera: {},
    }
  },
  watch: {
    value: {
      handler(camera) {
        this.camera = camera
      },
      immediate: true,
    },
    camera(camera) {
      this.$emit("change", camera)
    },
  },
}
</script>
